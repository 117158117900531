.footer {
  position: relative;
}

.footer-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  z-index: 5;
  bottom: 0;
  & .footer-copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 220px;
    flex-grow: 1;
    gap: 5px;
    & p {
      font-family: Montserrat, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      color: #fff;
    }
    & span {
      font-family: Open Sans;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      color: #fff;
    }
    & .footer-icon {
      color: #fff;
    }
  }
  & .footer-socials {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background-color: #000;
    width: 220px;
    height: 75px;
    border-radius: 100% 1% 0% 0%;
    & a {
      color: #fff;
      font-size: 18px;
    }
  }
}
.sticky-wp {
  position: absolute;
  bottom: 115px;
  right: 70px;
  width: 64px;
  height: 64px;
  line-height: 77px;
  border-radius: 50%;
  text-align: center;
  background-color: #ffcc29;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  & a {
    color: #000;
    font-size: 30px;
    line-height: 30px;
  }
}

.sticky-wp.main-page {
  position: fixed;
  bottom: 120px;
  right: 70px;
}

.fixed-footer {
  position: fixed;
  bottom: 0;
}

.relative-footer {
  position: relative;
}

.footer-copyright.main-page .footer-icon,
.footer-copyright.main-page span,
.footer-copyright.main-page p {
  color: #fff;
}

.footer-copyright.other-page .footer-icon,
.footer-copyright.other-page span,
.footer-copyright.other-page p {
  color: #000;
}

.footer-socials.main-page {
  background-color: #000;
  color: #fff;
}

.footer-socials.other-page {
  background-color: #fff;
  a {
    color: #000;
  }
}

@media (max-width: 1200px) {
  .footer-wrapper {
    flex-direction: column;
    .footer-copyright {
      position: absolute;
      padding: 10px;
      margin-left: 0;
    }
  }
  .sticky-wp {
    right: 30px !important;
  }
  .footer-socials {
    width: 100% !important;
    border-radius: 100% 100% 0% 0% !important;
    padding-top: 20px;
  }
}
@media (max-width: 450px) {
  .footer-copyright {
    padding: 40px !important;
  }
  .footer-wrapper {
    & .footer-socials {
      height: 130px;
      gap: 10px !important;
    }
  }
  .footer-socials {
    padding-top: 50px !important;
  }
}
@media (max-width: 992px) {
  .footer-wrapper .other-page {
    color: white !important;
  }

  .footer-wrapper .other-page svg {
    color: #fff !important;
  }
  .footer-socials.other-page {
    background-color: black !important;
    color: white !important;
  }
  .footer-wrapper .main-page {
    /* background-color: white !important; */
    color: black !important;
  }

  .footer-wrapper .footer-socials a,
  .footer-wrapper .footer-copyright span,
  .footer-wrapper .footer-copyright p {
    color: inherit !important;
  }

  .sticky-wp a {
    color: inherit !important;
  }
  .footer-copyright {
    padding: 40px !important;
  }
  .footer-wrapper {
    & .footer-socials {
      height: 130px;
      gap: 10px !important;
    }
  }
  .footer-socials {
    padding-top: 50px !important;
  }
}
