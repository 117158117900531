* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat";
}
@font-face {
  font-family: Montserrat;
  src: url(./assets/fonts/Montserrat-VariableFont_wght.ttf) format("truetype");
}
@font-face {
  font-family: Montserrat-600;
  src: url(./assets/fonts/Montserrat-SemiBold.ttf);
}
@font-face {
  font-family: Montaga;
  src: url(./assets/fonts/Montaga-Regular.ttf);
}

@media (max-width: 768px) {
  .header-logo img {
    width: 170px !important;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffcd2943;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffcc29;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e7b921;
}
