a {
  text-decoration: none;
}
.active {
  color: #ffcc29 !important;
}
.header-logo img {
  width: 236px;
}
.headerBg {
  background-color: #fff;
  box-shadow: -1px 2px 18px -15px rgba(0, 0, 0, 0.75);
  transition: all 0.3s;
}
.header-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 9;
  .container-header {
    width: 90%;
  }
  .header-links {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    a {
      font-family: Montserrat, sans-serif;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      color: #000;
    }
  }
}

.language-search-content {
  display: flex;
  align-items: end;
  flex-direction: column;
  position: relative;
  gap: 2px;
  .search img {
    width: 32px;
    cursor: pointer;
  }
  .language-wrapper {
    .languages {
      list-style: none;
      display: flex;
      gap: 10px;
      a {
        color: #000;
        font-family: Montserrat, sans-serif;
        font-size: 16px;
        font-weight: 400;
        cursor: pointer;
      }
      .active-lang {
        color: #ffcc29 !important;
      }
    }
  }
  .search svg {
    font-size: 30px;
    cursor: pointer;
  }
}
.ant-space-item {
  font-size: 16px;
  margin-left: 6px;
}
.contact-message .ant-space-item {
  margin-left: 0px !important;
}
.menu-icon {
  display: none;
  font-size: 40px;
  cursor: pointer;
}
.dropdown-language {
  display: none;
  cursor: pointer;
}
.active-language {
  color: #ffcc29;
}
.ant-drawer-header.ant-drawer-header-close-only {
  border-bottom: none !important;
}
.menu-logo {
  width: 180px;
}
.ant-drawer .ant-drawer-header-title {
  display: flex;
  flex-direction: row-reverse;
}
.ant-drawer .ant-drawer-content {
  background-image: url("../../assets/images/png/background-menu.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  .anticon.anticon-close {
    color: #fff;
  }
}

.ant-drawer-close {
  font-size: 23px !important;
}
.header-links-menu {
  display: flex;
  align-items: end;
  flex-direction: column;
  gap: 14px;
  a {
    font-family: Montserrat, sans-serif;
    font-size: 28px;
    font-weight: 400;
    line-height: 34px;
    color: #fff;
  }
}

@media (max-width: 1200px) {
  .md-d-none {
    display: none !important;
  }
  .header-links {
    display: none !important;
  }
  .menu-icon {
    display: block !important;
  }
  .language-search-content {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 13px;
    .language-wrapper {
      position: relative;
      bottom: 100%;
      .languages {
        display: none !important;
      }
    }
    .search img {
      width: 28px;
    }
  }
  .dropdown-language {
    display: block !important;
  }
  .ant-space-item {
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-weight: 400;
  }
  .ant-modal .ant-modal-body {
    input {
      width: 70vw !important;
      padding-bottom: 10px;
    }
  }
  .modal-search-icon {
    right: 11% !important;
  }
  .header-wrapper {
    .header-links {
      gap: 20px;
    }
  }
}
.ant-modal .ant-modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img {
    width: 150px;
  }
  input {
    outline: none;
    border: none;
    border-bottom: 1px solid #000;
    width: 50vw;
    padding: 10px;
  }
}
.ant-modal .ant-modal-footer {
  display: none !important;
}
.ant-modal .ant-modal-close:hover {
  background-color: transparent !important;
}
.ant-modal-close-x .anticon svg {
  color: #000;
}
.modal-input {
  position: relative;
}
.modal-search-icon {
  position: absolute;
  font-size: 22px;
  right: 10%;
  bottom: 22%;
}

.l-centered-dropdown .ant-dropdown-menu,
.centered-dropdown .ant-dropdown-menu {
  max-height: 500px;
  overflow-y: auto !important;
}

.l-centered-dropdown .ant-dropdown-menu::-webkit-scrollbar,
.centered-dropdown .ant-dropdown-menu::-webkit-scrollbar {
  width: 8px !important;
}

.l-centered-dropdown .ant-dropdown-menu::-webkit-scrollbar-track,
.centered-dropdown .ant-dropdown-menu::-webkit-scrollbar-track {
  background: #ccc2a0 !important;
  border-radius: 10px !important;
}

.l-centered-dropdown .ant-dropdown-menu::-webkit-scrollbar-thumb,
.centered-dropdown .ant-dropdown-menu::-webkit-scrollbar-thumb {
  background: #ffcc29 !important;
  border-radius: 10px !important;
}

.centered-dropdown {
  left: 50% !important;
  transform: translateX(-50%) !important;
}
.l-centered-dropdown {
  left: 50% !important;
}
.anticon {
  cursor: pointer;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-active.ant-dropdown-menu-item-only-child:hover,
.ant-select-item.ant-select-item-option.ant-select-item-option-active:hover {
  background-color: #ffcc29;
}
.ant-modal-root .ant-modal-wrap {
  overflow: hidden;
}
.ant-drawer-content-wrapper {
  width: 100% !important;
}
.header-links-menu .dropdown-content{
  -webkit-overflow-scrolling: touch;
}
.header-links-menu .dropdown-content::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.header-links-menu .dropdown-content::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #fff;
}
.header-links-menu .dropdown-content::-webkit-scrollbar-thumb {
  background: #ffcc29;
  border-radius: 10px;
}
.header-links-menu .dropdown-content::-webkit-scrollbar-thumb:hover {
  background: #ffcc29;
}
.header-links-menu .dropdown-content::-webkit-scrollbar-corner {
  background-color: transparent;
}
.header-links-menu .menu-list a {
  font-size: 20px;
  line-height: 26px;
}
.nav-links {
  transition: margin-top 0.3s ease;
}

.nav-links.dropdown-open {
  margin-top: 500px;
}
@media (max-width: 450px) {
  .header-links-menu .menu-list a {
    font-size: 15px !important;
  }
  .header-links-menu .dropdown-content {
    width: 97% !important;
    scrollbar-width: thin;
    scrollbar-color: #ffcc29 #fff;
  }
}
