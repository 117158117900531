.mySwiper {
  width: 100%;
  height: 100vh;
}

.swiper-slide img {
  /* display: block; */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-container {
  width: 100%;
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.overlay-light {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
}
.swiper-content {
  position: relative;
  .slider-about {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    .text {
      h1 {
        font-family: Montserrat, sans-serif;
        font-size: 72px;
        font-weight: 500;
        text-transform: uppercase;
      }
      span {
        font-family: Montserrat, sans-serif;
        font-size: 70px;
        font-weight: 500;
        text-transform: uppercase;
      }
    }
    p {
      font-family: Montserrat, sans-serif;
      font-size: 20px;
      font-weight: 400;
      border-left: 6px solid #ffcc29;
      padding-left: 12px;
    }
    .slider-buttons {
      margin-top: 50px;
      a {
        color: #fff;
        font-family: Montserrat, sans-serif;
        font-size: 26px;
        font-weight: 500;
        line-height: 32px;
        border: 3px solid #fff;
        padding: 10px 70px;
        &:hover {
          background-color: #ffcc29;
          color: #fff;
          transition: all 0.3s ease;
          border-color: #ffcc29;
        }
      }
      .slider-contact-button {
        margin-left: 40px;
      }
    }
    .text-slider2 h2 {
      font-family: Montserrat, sans-serif;
      font-size: 60px;
      font-weight: 500;
      line-height: 70px;
      color: #000;
    }
    .counter-text {
      margin-top: 70px;
      h3 {
        font-family: Montaga;
        font-size: 80px;
        font-weight: 400;
        line-height: 60px;
        color: #000;
        margin-bottom: 27px;
      }
      span {
        font-family: "Montserrat";
        font-size: 21px;
        font-weight: 400;
        line-height: 40px;
        color: #000;
      }
    }
    .slider-counter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
  .slider-buttons-slider2 {
    margin-top: 100px;
    .slider-contact-button {
      margin-left: 40px;
    }
    a {
      color: #000;
      border: 3px solid #000;
      font-family: Montserrat, sans-serif;
      font-size: 26px;
      font-weight: 500;
      line-height: 32px;
      padding: 10px 70px;
      &:hover {
        background-color: #ffcc29;
        color: #fff;
        transition: all 0.3s ease;
        border-color: #ffcc29;
      }
    }
  }
}

.swiper-pagination-bullet-active {
  background: #fff !important;
  width: 8px !important;
  height: 27px !important;
  border-radius: 6px !important;
}
.swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-vertical.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: var(--swiper-pagination-bullet-vertical-gap, 20px) 0 !important;
  display: block;
}
.container-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 15px 0px;
}

.mobile-image {
  display: none;
}
.desktop-image {
  display: block !important;
}

@media (max-width: 978px) {
  .slider-buttons,
  .slider-buttons-slider2 {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 15px;
    a {
      font-size: 22px !important;
      line-height: 27px !important;
      padding: 10px 30px !important;
      font-weight: 600 !important;
    }
  }
  .slider-contact-button {
    margin-left: 0px !important;
  }
  .text-slider2 h2 {
    font-size: 36px !important;
    line-height: 40px !important;
  }
  .swiper-content .slider-about .text span {
    font-size: 26px !important;
    line-height: 5px !important;
  }
  .swiper-content .slider-about .text h1 {
    font-size: 40px !important;
    line-height: 30px !important;
  }
  .swiper-pagination {
    display: none;
  }
  .swiper-slide img {
    width: auto;
    height: 100vh;
  }
  .overlay-container {
    position: initial;
  }
}
@media (max-width: 768px) {
  .swiper-content {
    & .slider-about {
      .counter-text {
        margin-top: 50px !important;
        h3 {
          font-size: 45px !important;
          line-height: 2px !important;
        }
      }
    }
  }
  .header-wrapper {
    & .container-header {
      padding: 25px 0px !important;
    }
  }
  .swiper-content {
    & .slider-about {
      width: 560px;
    }
  }
  .slider-about-desc {
    padding-top: 15px !important;
    p {
      border-left: 3px solid #ffcc29 !important;
    }
  }
  .desktop-image {
    display: none !important;
  }
  .mobile-image {
    display: block;
    width: 100% !important;
  }
}

@media (max-with: 576px) {
  .swiper-content {
    & .slider-about {
      width: 460px;
    }
  }
}
@media (max-width: 450px) {
  .swiper-content .slider-about .text h1 {
    font-size: 31px !important;
    line-height: 30px !important;
  }
  .slider-about p {
    font-size: 14px !important;
    line-height: 22px;
  }
  .swiper-content {
    & .slider-about {
      width: 330px;
    }
    .swiper-slide img {
      width: auto;
      height: 100vh;
    }
  }

  .contact-form-wrapper .contact-button button {
    padding: 9px 26px !important;
    font-size: 16px;
  }
  .contact-form-wrapper p {
    font-size: 30px !important;
    line-height: 19px !important;
  }
  .contact-form-wrapper .contact-message input,
  .contact-form-wrapper .contact-message .ant-select-selector {
    font-size: 15px !important;
  }
  .contact-form-wrapper .container {
    width: 100%;
    left: 0%;
  }
  .contact-form-wrapper .contact-button {
    margin-top: 30px;
  }
  .swiper-content {
    & .slider-about {
      & .counter-text {
        span {
          font-size: 17px;
        }
      }
    }
  }
  .swiper-content {
    & .slider-about {
      & .counter-text {
        h3 {
          font-size: 40px !important;
          line-height: 0px !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 1537px) {
  .swiper-content {
    & .slider-about {
      & .text {
        h1 {
          font-size: 100px;
        }
      }
    }
  }
}
