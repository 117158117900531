.contact-wrapper {
  position: relative;
  height: calc(100vh - 75px);
  .contact-content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100vh;
    & .container {
      width: 60%;
      max-width: 100%;
      position: absolute;
      bottom: 20%;
      &::after {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-image: url("../../assets/images/contact/background-contact.png");
        background-size: cover;
        background-position: center;
        opacity: 0.5;
        z-index: -1;
      }
    }
    p {
      font-size: 50px;
      font-weight: 500;
      line-height: 85px;
    }
    .contact-details {
      margin-top: 60px;
      .contact-item {
        margin: 10px;
        display: flex;
        align-items: star;
        gap: 10px;
        span {
          font-size: 30px;
          font-weight: 600;
          line-height: 36px;
        }
        p {
          font-size: 30px;
          font-weight: 400;
          line-height: 36px;
        }
        a {
          font-size: 30px;
          font-weight: 400;
          line-height: 36px;
          color: #000;
          text-decoration: underline;
        }
        .contact-phone {
          font-size: 30px;
          font-weight: 400;
          line-height: 36px;
          color: #000;
          text-decoration: none;
        }
      }
    }
    .contact-button {
      margin-top: 50px;
      button {
        background-color: #ffcc29;
        border-radius: 10px;
        padding: 15px 30px;
        text-align: center;
        border: none;
        border: 3px solid #ffcc29;
        color: #000;
        font-size: 25px;
        font-weight: 600;
        cursor: pointer;
        &:hover {
          background-color: #fff;
          transition: all 0.3s ease;
          border: 3px solid #ffcc29;
        }
      }
    }
  }
}
@media only screen and (min-width: 1537px) {
  .contact-wrapper .contact-content {
    p {
      font-size: 70px;
    }
    .container {
      bottom: 30%;
    }
    .contact-button button {
      font-size: 30px;
    }
  }
}
@media (max-width: 1200px) {
  .contact-wrapper .contact-content {
    .container {
      width: 90%;
      bottom: 14%;
    }
  }
}
@media (max-width: 992px) {
  .contact-button button {
    width: 100%;
  }
  .contact-wrapper {
    .contact-content {
      .contact-details {
        margin-top: 35px;
        .contact-item span,
        p,
        .contact-phone,
        a {
          font-size: 24px;
        }
      }
      p {
        font-size: 40px;
      }
    }
  }
}
@media (max-width: 450px) {
  .contact-content {
    p {
      font-size: 30px;
      font-weight: 500;
      line-height: 36px;
    }
    .contact-item {
      p,
      a {
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 23px !important;
      }
      span {
        font-size: 16px !important;
        line-height: 20px !important;
      }
    }
    .contact-button {
      margin-top: 130px !important;
      margin-bottom: 40px !important;
      button {
        font-size: 22px !important;
        width: 100%;
      }
      .contact-details {
        margin-top: 30px !important;
      }
      .main-p p {
        font-size: 30px !important;
      }
      .container {
        bottom: 30% !important;
      }
    }
  }
  .contact-wrapper .contact-content .main-p p {
    font-size: 30px !important;
  }
  .contact-wrapper .contact-content .contact-details {
    margin-top: 20px !important;
  }
}
