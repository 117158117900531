.services_bg {
  width: 100%;
  opacity: 0.6;
  display: none;
}
.services {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 200px 200px 100px;

  &::after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-image: url("../../assets/images/png/services-bg.jpg");
    background-size: cover;
    background-position: center;
    opacity: 0.5;
    z-index: -1;
  }

  &_back_button {
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 5px;
    transition: ease-out 0.15s;
    cursor: pointer;
    color: inherit;
    text-decoration: none;
    margin-top: -50px;

    &:hover {
      gap: 15px;
      transition: ease-in 0.15s;
    }
  }
}

@media (max-width: 1410px) {
  .services {
    padding: 250px 100px 100px;
  }
}

@media (max-width: 1050px) {
  .services {
    padding: 200px 50px 100px;

    &_back_button {
      margin-top: -70px;
    }
  }
}

@media (max-width: 780px) {
  .services_bg {
    display: block;
    z-index: -1;
  }
  .services {
    padding: 10px 0 30px 0;

    &_back_button {
      display: none;
    }

    &::after {
      display: none;
    }
  }
}
