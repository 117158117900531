.accordion-item {
  width: 100%;
  background-color: white;
  border-radius: 13px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  overflow: hidden;
  transition: all 0.3s ease;
  &.open {
    .accordion-title {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      transition: all 0.3s, visibility 0.3s;
    }

    .accordion-content {
      max-height: 1000px; 
      padding: 0 20px 20px;
    }
  }
  .accordion-title {
    padding: 20px;
    background-color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s, visibility 0.3s;
    h3 {
      margin: 0;
      font-size: 20px;
      color: #000000;
      font-weight: 400;
    }

    span {
      font-size: 1.2rem;
      color: #666;
    }
  }
  .accordion-content {
    max-height: 0;
    padding: 0 20px;
    background-color: #fff;
    overflow: hidden;
    transition: max-height 0.3s ease, padding 0.3s ease;

    .accordion-content-inner{
      font-size: 16px;
    }
  }
}
@media only screen and (min-width: 1367px) {
  .accordion-item {
    .accordion-title {
      h3 {
        font-size: 30px;
      }
      span {
        font-size: 18px;
      }
    }
    .accordion-content {
      .accordion-content-inner{
        font-size: 18px;
      }
    }
  }
}
@media only screen and (max-width: 425px) {
  .accordion-item {
    .accordion-title {
      h3 {
        font-size: 1.1rem;
      }
      span {
        font-size: 1rem;
      }
    }
  }
}
