.certificate_detail {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 50px;

  &_head {
    display: flex;
    flex-direction: column;
    gap: 10px;

    h1 {
      font-size: 44px;
      font-weight: 600;
      line-height: 53px;
    }

    h3 {
      font-size: 30px;
      font-weight: 400;
      line-height: 26px;
    }
  }

  &_main {
    display: flex;
    align-items: start;
    gap: 25px;

    &_content {
      width: 70%;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      gap: 30px;
      padding: 60px 30px 40px;
      border-radius: 7px;
      box-shadow: 0px 4px 4px 0px #00000040;

      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-track {
        background-color: #ffcd2943;
        border-radius: 12px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #ffcc29;
        border-radius: 12px;
      }

      h3 {
        font-size: 18px;
        font-weight: 600;
      }

      p {
        font-size: 18px;
        font-weight: 400;
      }
    }

    &_right {
      width: 30%;
      display: flex;
      flex-direction: column;
      gap: 20px;

      &_certificates {
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 0px 4px 4px 0px #00000040;

        &_head {
          font-size: 24px;
          font-weight: 600;
          line-height: 26px;
          padding: 20px;
          background-color: #e0e0e0;
          box-shadow: 0px 2px 2px 0px #00000040;
          border-radius: 20px 20px 0 0;
        }

        &_container {
          padding: 20px;
          display: flex;
          flex-direction: column;
          gap: 15px;

          .certificate_information {
            display: flex;
            flex-direction: column;
            gap: 5px;
            font-size: 16px;
            font-weight: 400;
            cursor: pointer;

            .certificate_name {
              font-size: 16px;
              font-weight: 400;

              .certificate_code {
                font-weight: 600;
                transition: ease-out 0.15s;
              }
            }

            &:hover .certificate_code {
              color: #ffcc29;
              transition: ease-in 0.15s;
            }
          }
        }
      }
    }
  }

  &_contact_button {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: #ffcc29;
    border: none;
    font-size: 26px;
    font-weight: 600;
    cursor: pointer;
    transition: ease-out 0.15s;
    color: #000;
    &:hover {
      color: #fff;
      transition: ease-in 0.15s;
    }
  }
}

.grey_line {
  width: 100%;
  height: 1px;
  background-color: #dadada;
}

.certificates_container_link {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

@media (max-width: 1410px) {
  .certificate_detail {
    &_head {
      h1 {
        font-size: 40px;
      }

      h3 {
        font-size: 26px;
      }
    }

    &_main {
      &_content {
        h3 {
          font-size: 16px;
        }

        p {
          font-size: 16px;
        }
      }

      &_right {
        &_certificates {
          &_head {
            font-size: 20px;
          }

          &_container {
            .certificate_information {
              font-size: 14px;

              .certificate_name {
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    &_contact_button {
      font-size: 22px;
    }
  }
}

@media (max-width: 1050px) {
  .certificate_detail {
    padding-top: 10px;

    &_head {
      h1 {
        font-size: 36px;
      }

      h3 {
        font-size: 22px;
      }
    }

    &_main {
      &_content {
        width: 50%;
        h3 {
          font-size: 14px;
        }

        p {
          font-size: 14px;
        }
      }

      &_right {
        width: 50%;
        &_certificates {
          &_head {
            font-size: 18px;
          }

          &_container {
            .certificate_information {
              font-size: 14px;

              .certificate_name {
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    &_contact_button {
      font-size: 18px;
    }
  }
}

@media (max-width: 780px) {
  .certificate_detail {
    padding: 10px 10px 80px;
    background-color: #fff;
    border-radius: 34px;
    box-shadow: 0px 4px 4px 0px #00000040;
    margin-top: -40px;

    &_head {
      align-items: center;
      gap: 0;
      h1 {
        font-size: 22px;
      }

      h3 {
        font-size: 20px;
      }
    }

    &_main {
      flex-direction: column;
      &_content {
        width: 100%;
        max-height: unset;
        box-shadow: unset;
        overflow-y: auto;

        h3 {
          font-size: 18px;
        }

        p {
          font-size: 18px;
        }
      }

      &_right {
        width: 100%;
        gap: 40px;
        &_certificates {
          &_head {
            font-size: 20px;
          }

          &_container {
            .certificate_information {
              font-size: 16px;

              .certificate_name {
                font-size: 16px;
              }
            }
          }
        }
      }
    }

    &_contact_button {
      font-size: 20px;
    }
  }
}
