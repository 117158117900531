.certificate {
  width: 100%;
  // height: 180px;
  background-color: #fff;
  border-radius: 20px;
  padding: 30px 25px;
  display: flex;
  flex-direction: column;
  gap: 13px;
  cursor: pointer;

  &_head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_label {
      font-size: 44px;
      font-weight: 600;
      line-height: 53px;
      transition: ease-out 0.15s;
    }

    &_arrow {
      transform: rotate(-90deg);
      margin-right: 15px;
      transition: ease-out 0.15s;
    }
  }

  &_name {
    font-size: 20px;
    font-weight: 300;
  }

  &:hover &_head_label {
    color: #ffcc29;
    transition: ease-in 0.15s;
  }
  &:hover &_head_arrow {
    margin-right: 0;
    transition: ease-in 0.15s;
  }
}

@media (max-width: 1410px) {
  .certificate {
    &_head {
      &_label {
        font-size: 40px;
        font-weight: 600;
        line-height: 53px;
        transition: ease-out 0.15s;
      }
    }

    &_name {
      font-size: 18px;
      font-weight: 300;
    }
  }
}

@media (max-width: 1050px) {
  .certificate {
    padding: 20px;
    gap: 8px;
    height: unset;
    &_head {
      &_label {
        font-size: 32px;
        font-weight: 600;
        line-height: 53px;
        transition: ease-out 0.15s;
      }

      &_arrow {
        width: 25px;
        height: 25px;
      }
    }

    &_name {
      font-size: 16px;
      font-weight: 300;
    }
  }
}

@media (max-width: 780px) {
  .certificate {
    gap: 5px;
    box-shadow: 0px 4px 4px 0px #00000040;
    &_head {
      &_label {
        font-size: 22px;
        line-height: 33px;
      }

      &_arrow {
        width: 18px;
        height: 18px;
      }
    }

    &_name {
      font-size: 13px;
    }
  }
}
