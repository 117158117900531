.certificates {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &_head_label {
    font-size: 70px;
    font-weight: 500;
    line-height: 85px;
  }

  &_container {
    display: grid;
    grid-template-columns: 49% 49%;
    column-gap: 2%;
    row-gap: 25px;

    &_link {
      text-decoration: none;
      color: inherit;
    }
  }
}

@media (max-width: 1410px) {
  .certificates {
    gap: 55px;

    &_head_label {
      font-size: 60px;
      line-height: 65px;
    }
  }
}

@media (max-width: 1050px) {
  .certificates {
    gap: 45px;

    &_head_label {
      font-size: 45px;
      line-height: 45px;
    }

    &_container {
      grid-template-columns: 100%;
      column-gap: unset;
      row-gap: 25px;
    }
  }
}

@media (max-width: 780px) {
  .certificates {
    gap: 35px;
    padding: 30px 30px 80px;
    background-color: #F1F1F1;
    border-radius: 34px;
    box-shadow: 0px 4px 4px 0px #00000040;
    margin-top: -40px;

    &_head_label {
        text-align: center;
      font-size: 30px;
      line-height: 35px;
    }

    &_container {
      row-gap: 15px;
    }
  }
}
